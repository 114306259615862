import {PaletteColorOptions, PaletteOptions, createTheme} from '@mui/material';
import colors from './colors';
import {accordionTheme} from './componentThemes/accordion.theme';
import {alertsTheme} from './componentThemes/alerts.theme';
import {cardTheme} from './componentThemes/card.theme';
import {dataGridTheme} from './componentThemes/dataGrid.theme';
import {dateTimeTheme} from './componentThemes/dateTime.theme';
import {elementsTheme} from './componentThemes/elements.theme';
import {inputsTheme} from './componentThemes/inputs.theme';
import {layoutTheme} from './componentThemes/layout.theme';
import {menuTheme} from './componentThemes/menu.theme';

const palette = {
  primary: {
    light: colors.cobaltLight,
    main: colors.cobalt,
    dark: colors.darkIndigo,
    contrastText: colors.calcite,
  },
  secondary: {
    light: colors.lightBadges6DefaultMinimal,
    main: colors.lightIconDefault,
    dark: colors.lightIconDefault,
    contrastText: colors.calcite,
  },
  success: {
    light: colors.lightBadges8SuccessMinimal,
    main: colors.lightBadges3Success,
    dark: colors.lightIconSuccess,
    contrastText: colors.calcite,
  },
  warning: {
    light: colors.lightBadges9WarningMinimal,
    main: colors.lightBadges4Warning,
    dark: colors.lightIconWarning,
    contrastText: colors.calcite,
  },
  error: {
    light: colors.lightBadges10DangerMinimal,
    main: colors.lightBadges5Danger,
    dark: colors.lightIconDanger,
    contrastText: colors.calcite,
  },

  // Custom Colors added to pallet to prevent errors when rendering chips and other components with the color
  // @see https://stackoverflow.com/questions/69481467/mui-error-cannot-read-properties-of-undefined-reading-drawer
  plumAccent: {
    light: colors.primaryPlum,
    main: colors.primaryPlum,
    dark: colors.primaryPlum,
    contrastText: colors.calcite,
  },
  darkBlueAccent: {
    light: colors.darkBlueGrey,
    main: colors.darkBlueGrey,
    dark: colors.darkBlueGrey,
    contrastText: colors.calcite,
  },
  tealAccent: {
    light: colors.darkTeal,
    main: colors.darkTeal,
    dark: colors.darkTeal,
    contrastText: colors.calcite,
  },
  task: {
    light: colors.task,
    main: colors.task,
    dark: colors.task,
    contrastText: colors.calcite,
  },
  taskGrouped: {
    light: colors.taskGrouped,
    main: colors.taskGrouped,
    dark: colors.taskGrouped,
    contrastText: colors.calcite,
  },
  wear: {
    light: colors.wear,
    main: colors.wear,
    dark: colors.wear,
    contrastText: colors.calcite,
  },
  wearGrouped: {
    light: colors.wearGrouped,
    main: colors.wearGrouped,
    dark: colors.wearGrouped,
    contrastText: colors.calcite,
  },
  group: {
    light: colors.group,
    main: colors.group,
    dark: colors.group,
    contrastText: colors.calcite,
  },
  // end custom colors

  divider: colors.lightDividerBlack,

  background: {
    paper: colors.calcite,
    default: colors.pageBgColor,
  },

  purple: {
    light: colors.cobalt,
    main: colors.navBarBgColor,
    dark: colors.navBarBgColor,
    contrastText: colors.calcite,
  },

  text: {
    primary: colors.dark,
    secondary: colors.lightIconDefault,
    disabled: colors.lightIconDisabled,
  },
} satisfies PaletteOptions & {
  [key in
    | 'plumAccent'
    | 'darkBlueAccent'
    | 'tealAccent'
    | 'task'
    | 'taskGrouped'
    | 'wear'
    | 'wearGrouped'
    | 'group'
    | 'purple']: PaletteColorOptions;
};

export const Font = {
  medium: 'Lato, sans-serif',
  bold: 'Lato Bold, sans-serif',
  semiBold: 'Lato Semi-Bold, sans-serif',
  light: 'Lato Light, sans-serif',
  semi: 'Lato Italic, sans-serif',
  thin: 'Lato Thin, sans-serif',
} as const;

const baseTheme = createTheme({
  palette,
  spacing: [0, 5, 10, 20, 30, 40, 50],
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: Font.medium,

    h1: {fontSize: '24px', fontWeight: 600},
    h2: {fontSize: '20px', fontWeight: 600},
    h3: {fontSize: '18px', fontWeight: 600},
    h4: {fontSize: '16px', fontWeight: 400},
    h5: {fontSize: '14px', fontWeight: 400},
    h6: {fontFamily: Font.bold, fontSize: '12px', fontWeight: 400},
    subtitle1: {fontSize: '12px'},
    // subtitle2: {},
    body1: {color: palette.text.primary},
    body2: {fontFamily: Font.light, fontSize: '14px'},
    button: {lineHeight: '1.29', textTransform: 'none', whiteSpace: 'nowrap'},
    // caption: {},
    // overline: {},
  },
});

export const theme = createTheme({
  ...baseTheme,
  components: {
    ...accordionTheme(baseTheme),
    ...alertsTheme(baseTheme),
    ...cardTheme(baseTheme),
    ...dataGridTheme(baseTheme),
    ...elementsTheme(baseTheme),
    ...inputsTheme(baseTheme),
    ...layoutTheme(baseTheme),
    ...menuTheme(baseTheme),
    ...dateTimeTheme(),
  },
});
